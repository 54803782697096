import { Button, Form, Space } from 'antd';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppStore } from '../../store';

export default function Header() {
  const decoded = jwtDecode(sessionStorage.token);
  const access = parseInt(decoded.result[0].access);

  const updateAuth = useAppStore.use.updateAuth();
  const setLoading = useAppStore.use.updateLoading();

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const btLogOut = () => {
    sessionStorage.setItem(
      'token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOlt7ImlkIjoxLCJsYXN0bmFtZSI6IngiLCJmaXJzdG5hbWUiOiJ4IiwiZW1haWwiOiJ4QHNwaWUuY29tIiwicGFzc3dvcmQiOiIiLCJhY2Nlc3MiOjAsImNyZWF0ZV90aW1lIjoiMjAyNC0xMS0wMVQwMDowMDowOC4wMDBaIn1dLCJpYXQiOjE3MzA0MTkyMDAsImV4cCI6MjUyNDYwODAwMH0.z5mT7sEZOzqQl81ok8NJCRFs2xprrf7-2us_QWi8F8U'
    );
    sessionStorage.setItem('auth', '');
    navigate('/');
    updateAuth(false);
  };

  const [form] = Form.useForm();

  return (
    <header
      role="banner"
      className="flex flex-col 2xl:px-72 lg:px-20 md:px-10 sm:px-20"
    >
      <nav
        className="w-full shadow-md bg-zinc-200"
        aria-label="Menu principal"
        role="navigation"
      >
        <div className="bg-white">
          <div className="flex justify-center ">
            <ul className="flex">
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/tools"
                  >
                    GESTION OUTILLAGE
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/clearances"
                  >
                    GESTION HABILITATIONS
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/codes"
                  >
                    CODES ANSI / IEC61850
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access > 2 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/password"
                    color="red"
                  >
                    PASSWORD
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/site"
                    color="red"
                  >
                    FORMATIONS SITE
                  </a>
                </li>
              ) : (
                <></>
              )}
              {/* {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="http://planning.spie-st.fr/planning.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PLANNING
                  </a>
                </li>
              ) : (
                <></>
              )} */}
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="null"
                  >
                    {'  '}
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="null"
                  >
                    {' '}
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access >= 0 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/profile"
                  >
                    PROFIL
                  </a>
                </li>
              ) : (
                <></>
              )}
              {access > 4 ? (
                <li className=" group pb-2 border-b-zinc-400 px-2 py-1 text-center text-base font-bold transition ease-in-out duration-500 hover:shadow-2xl">
                  <div className="block content-none bg-blue-spie -top-0.5 h-px w-0 transition-all duration-400 ease-out group-hover:w-full"></div>
                  <a
                    role="button"
                    className="block text-sm"
                    aria-expanded="false"
                    href="/admin"
                  >
                    ADMIN
                  </a>
                </li>
              ) : (
                <></>
              )}
            </ul>
            <div className="flex justify-end">
              <Form className="" form={form}>
                <div className="">
                  <Space>
                    <Form.Item
                      style={{
                        margin: '0.5rem',
                      }}
                    >
                      <Button
                        type="primary"
                        ghost
                        onClick={() => {
                          btLogOut();
                        }}
                      >
                        Logout
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
