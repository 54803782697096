import { Button, Form } from 'antd';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { Axios } from '../../config';
import Logged from '../Header/Logged';
import CardAjoutSupplier from './CardAjoutSupplier';
import CardAjoutUtil from './CardAjoutUtil';
import CardNivAccess from './CardNivAccess';
import CardReinitMdp from './CardReinitMdp';
import Employed from './Employed';

import '../../../src/style_tailwind.css';

import NotyfContext from '../../Context/NotyfContext';

export default function Admin() {
  const decoded = jwtDecode(sessionStorage.token);
  const access = parseInt(decoded.result[0].access);
  const [updatecde, setUpdateCde] = useState('');
  const [userslist, setUserslist] = useState([]);
  const [user, setUser] = useState([]);
  const [searchcol, setSearchcol] = useState([]);
  const [buttonpopupajoututil, setButtonPopupAjoutUtil] = useState(false);
  const [buttonpopupreinitmdp, setButtonPopupReinitMdp] = useState(false);
  const [buttonpopupnivaccess, setButtonPopupNivAccess] = useState(false);
  const [buttonpopupajoutsupplier, setButtonPopupAjoutSupplier] =
    useState(false);
  const [error, setError] = useState('');
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    Axios.get('/api/get_users')
      .then((response) => {
        setUserslist(response.data);
        setUpdateCde('0');
      })
      .catch((err) => {
        setError(err);
      });
  }, [updatecde, error]);

  const onFinishAjoutUtil = async (values) => {
    await Axios.post('/api/insertusers', {
      lastname: values.lastname,
      firstname: values.firstname,
      email: values.email,
      password: values.password,
      access: values.access,
    }).then(
      setUserslist([
        ...userslist,
        {
          lastname: values.lastname,
          firstname: values.firstname,
          email: values.email,
          password: values.password,
          access: values.access,
        },
      ]),
      setUpdateCde('1'),
      setButtonPopupAjoutUtil(false),
      notyf.open({ type: 'success', message: 'Created' })
    );
  };

  const onFinishReinitMdp = async (value) => {
    await Axios.put('/api/updatereinitmdp', {
      id: value.useridmdp,
      password: 123456,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setButtonPopupReinitMdp(false);
        notyf.success(`Mot de passe changé`);
      }
    });
  };

  const onFinishNivAccess = async (value) => {
    await Axios.put('/api/updatenivaccess', {
      id: value.userid,
      access: value.access,
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setButtonPopupNivAccess(false);
        notyf.success(`Niveau modifié`);
      }
    });
  };

  const onFinishAjoutSupplier = async (value) => {
    await Axios.post('/api/insertsupplier', {
      supplier_name: value.supplier_name.toUpperCase(),
      supplier_data: '',
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setButtonPopupAjoutSupplier(false);
        notyf.success('Founisseur Ajouté');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    setError(errorInfo).then(
      notyf.open({ type: 'error', message: `${errorInfo}` })
    );
  };

  const handleChangeColReinitMdp = () => {};

  const handleChangeColNivAccess = (value) => {
    setSearchcol(value);
    const user = userslist.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
    setUser(user);
  };

  const onSearch = (value) => {
    setUserslist(value);
    // console.log(userslist);
    form.resetFields();
  };

  // const onReset = () => {
  //   form.resetFields();
  // };

  const [form] = Form.useForm();

  const onClickNivAccess = () => {
    setButtonPopupNivAccess(true);
    handleChangeColNivAccess();
  };

  return (
    <>
      <Logged />
      <main className="overflow-hidden place-items-center">
        <div className="flex flex-col m-2 shadow-xl max-w-6xl">
          <div className="flex m-2">
            <div className="flex bg-white m-3 border-2 border-blue-spie">
              <Button onClick={() => setButtonPopupAjoutUtil(true)}>
                Ajouter un Utilisateur
              </Button>
            </div>
            <div className="flex bg-white m-3 border-2 border-blue-spie">
              <Button onClick={() => setButtonPopupReinitMdp(true)}>
                Réinitialiser MDP
              </Button>
            </div>
            {access > 5 ? (
              <div className="flex bg-white m-3 border-2 border-blue-spie">
                <Button onClick={onClickNivAccess}>
                  Modification Niveau Acces
                </Button>
              </div>
            ) : (
              <></>
            )}
            {access > 5 ? (
              <div className="flex bg-white m-3 border-2 border-blue-spie">
                <Button onClick={() => setButtonPopupAjoutSupplier(true)}>
                  Ajout d'un fournisseur
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          {buttonpopupajoututil === true ? (
            <CardAjoutUtil
              userslist={userslist}
              setButtonPopup={setButtonPopupAjoutUtil}
              handChangeCol={handleChangeColReinitMdp}
              onFinish={onFinishAjoutUtil}
              onFinishFailed={onFinishFailed}
              onSearch={onSearch}
            />
          ) : (
            <></>
          )}
          {buttonpopupreinitmdp === true ? (
            <CardReinitMdp
              userslist={userslist}
              setButtonPopup={setButtonPopupReinitMdp}
              handChangeCol={handleChangeColReinitMdp}
              onFinish={onFinishReinitMdp}
              onFinishFailed={onFinishFailed}
              onSearch={onSearch}
            />
          ) : (
            <></>
          )}
          {buttonpopupnivaccess === true ? (
            <CardNivAccess
              userslist={userslist}
              setUserslist={setUserslist}
              user={user}
              setButtonPopup={setButtonPopupNivAccess}
              handleChangeCol={handleChangeColNivAccess}
              onFinish={onFinishNivAccess}
              onFinishFailed={onFinishFailed}
              onSearch={onSearch}
              searchcol={searchcol}
            />
          ) : (
            <></>
          )}
          {buttonpopupajoutsupplier === true ? (
            <CardAjoutSupplier
              setButtonPopup={setButtonPopupAjoutSupplier}
              onFinish={onFinishAjoutSupplier}
              onFinishFailed={onFinishFailed}
            />
          ) : (
            <></>
          )}
          <Employed></Employed>
        </div>
      </main>
    </>
  );
}
